<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search ele-form-mysearch d-flex f-end"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <el-form-item label="选择状态:" label-width="80px" class="w-200">
          <el-select v-model="table.where.pay_type" placeholder="请选择" clearable class="ele-fluid">
            <el-option  label="全部" value="All"/>
            <el-option  label="成功" value="Success"/>
            <el-option label="失败" value="Business Failed"/>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号:" label-width="74px" class="w-300">
          <el-input v-model="table.where.out_biz_no" placeholder="请输入订单编号" clearable/>
        </el-form-item>
        <el-form-item label="省:" label-width="35px" class="w-170">
          <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-150">
          <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="62px" class="w-200">
          <el-select v-model="table.where.aid"  @change="$forceUpdate()" placeholder='请选择县/区' clearable>
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon ml-20">搜索</el-button>
          <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
          <el-button type="primary" @click=" handleTrans"  class="el-icon-s-promotion" v-if="permission.includes('sys:finance_order_errands:transfer')">转账</el-button>
        </el-form-item>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip/>
          <el-table-column prop="out_biz_no" label="地区"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="pay_date" label="订单号"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="transfer_status" label="订单类型"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="sub_msg" label="用户"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="phone" label="用户手机号"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="name" label="商户"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="price" label="商户手机号"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="set_area" label="支付方式"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="pay_date" label="订单号"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="transfer_status" label="订单类型"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="sub_msg" label="用户"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="phone" label="用户手机号"  show-overflow-tooltip min-width="120"/>
          <el-table-column prop="name" label="商户"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="price" label="商户手机号"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="set_area" label="支付方式"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="pay_date" label="订单金额"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="transfer_status" label="含零钱金额"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="sub_msg" label="实际到账"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="phone" label="手续费"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="name" label="是否含有红包"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="price" label="红包金额"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="set_area" label="违约金金额"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="set_area" label="下单时间"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="set_area" label="支付时间"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="set_area" label="备注"  show-overflow-tooltip min-width="100"/>
          <!--          <el-table-column prop="set_area" label="操作"  show-overflow-tooltip min-width="160"/>
          -->









          <!--
                    <el-table-column type="selection" width="45" align="center" fixed="left"/>
                    <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
                    <el-table-column prop="out_biz_no" label="订单编号"  show-overflow-tooltip min-width="220"/>
                    <el-table-column prop="pay_date" label="转账时间"  show-overflow-tooltip min-width="140"/>
                    <el-table-column prop="transfer_status" label="转账状态"  show-overflow-tooltip min-width="120"/>
                    <el-table-column prop="sub_msg" label="失败原因"  show-overflow-tooltip min-width="260"/>
                    <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
                    <el-table-column prop="name" label="姓名"  show-overflow-tooltip min-width="100"/>
                    <el-table-column prop="price" label="转账金额(元)"  show-overflow-tooltip min-width="140"/>
                    <el-table-column prop="set_area" label="所属地区"  show-overflow-tooltip min-width="160"/>-->

          <el-table-column label="操作" width="150px" align="center" :resizable="false"  fixed="right">
            <template slot-scope="{row}">
              <el-link slot="reference" @click="ali_transfer(row)" icon="el-icon-s-promotion" type="primary" :underline="false" v-if="permission.includes('sys:finance_order_errands:transfer')">转账</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>

    <el-dialog v-dialogDrag title="支付宝转账" :visible.sync="showEdit"
               @closed="form={}" :destroy-on-close="true" :lock-scroll="false">
      <el-card  shadow="never">
        <el-form :model="form" ref="form" :rules="editRules" label-width="150px" class="mt-20 driverForm" >
          <el-form-item label="超级管理员手机号：" prop="form" >
            <el-input v-model="scope" placeholder="" clearable class='input163' disabled/>
            <el-button type="primary" @click="handleCode"  class="ele-btn-icon ml-20" v-if="show">获取验证码</el-button>
            <el-button icon=""  v-else style="background-color:#33cc99; border-color:#33cc99;m" class="ml-20" type="primary">{{count}}秒后重试</el-button>
          </el-form-item>
          <el-form-item label="转账手机号：" prop="price">
            <el-input v-model="form.phone" controls-position="right" placeholder="" clearable class="input163"/>
          </el-form-item>
          <el-form-item label="转账金额(元)：" prop="price" >
            <el-input v-model="form.price" controls-position="right" placeholder="" clearable class="input163"/>
          </el-form-item>
          <el-form-item label="验证码：" >
            <el-input v-model="form.code" controls-position="right" placeholder="" clearable class="input163"/>

          </el-form-item>
        </el-form>

      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>

    </el-dialog>

  </div>
</template>
<style>
.ele-form-mysearch{float: right}
</style>
<script>


import { mapGetters } from "vuex";

export default {
  name: "SysUser",
  data() {
    return {
      scope:'159****8888',
      count:'',
      show: true,
      table: {url: '/PayLog/alipay_log_list', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      editForm: {},  // 表单数据
      checkForm:{},
      editRules: {  // 表单验证规则

      },
      showEdit:false,
      dialogTableVisibleCheck:false,
      dialogTableVisibleChatRecord:false,
      provArr:[],
      cityArr:[],
      districtArr:[],
      audioArr:[],
      urgentList:[],
      imageUrl:false,
      headImg:'',
      zoom:12,
      center:[104.07,30.67],
      form:{},
    }
  },
  created(){
    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })
  },


  computed: {
    ...mapGetters(["permission"]),
  },

  mounted() {
  },
  methods: {
    save(){
      this.form.type=2
      this.$http.post('/finance/alipay_transfer', this.form).then(res => {
        if(res.data.code==0){
          this.showEdit=true;
          this.$message({type: 'success', message: res.data.msg});
        }else{
          this.$message.error(res.data.msg);
        }

      }).catch(e=>{
        this.$message.error(e.message);
      })
    },
    handleTrans(){
      this.showEdit=true;

    },
    //获取验证码
    handleCode(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
      this.$http.post('/login/getCode', {phone:'13385321361'}).then(res => {
        if (res.data.code === 0) {
          this.$message({type: 'success', message: res.data.msg});
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     *选择省
     **/
    handleChangeProv(e){
      //console.log(e)
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
      if(e){
        /** 获取被选市的cid**/
        let cid=''
        this.cityArr.forEach(function(item){
          if(item.cid==e){
            cid=item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list',{cid:cid}).then(res=>{
          let data=JSON.parse(res.data)
          this.districtArr=data
          /** 选择省份清空县**/
          this.table.where.area_id=''
        })
      }else{
        this.table.where.pid = ''
        this.table.where.area_id = ''
      }
    },
    //申请驳回操作
    ali_transfer(row){
      this.$confirm('确定要转账吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/PayLog/ali_transfer', {id: row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })
    },

  }
}
</script>

